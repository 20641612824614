





















































































.booking-type
  width 90%
  background-color #0000000d
  font-size 12px
  padding 5px 0
